import React from 'react'
import QRCode from 'react-qr-code'

const Qrcode = () => {
  return (
    <div className='container'><h2>Website QR Code</h2>
    <QRCode value="https://domaintobesocial.com/" size={500}/></div>
  )
}

export default Qrcode