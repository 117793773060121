import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Picker from "emoji-picker-react";
import UserContext from '../../Hooks/UserContext';

const CreateFeed = ({checkedItems,setCheckedItems,handlecreatepost}) => {
 
      
      const [input, setInput] = useState({});
      const [formfilled, setFormfilled] = useState("notempty");
      const [isViprole, setIsViprole] = useState(false);
      const [userimage, setUserimage] = useState("/images/blank.png");
      const [enteredText, setEnteredText] = useState([]);
      const [errors, setErrors] = useState({});
      const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
      const [videosPreviewUrls, setVideosPreviewUrls] = useState([]);

      const [childVisible, setChildVisible] = useState(false);
      const [videos, setVideos] = useState([]);
      const [files, setFiles] = useState([]);
      const {updatevalue, setupdatevalue} = useContext(UserContext);


      useEffect(()=> {
      if(input.posttype=="nonvip"){
        const modal = window.$('#exampleModalHelp');
        if (modal.hasClass('show')) {
          modal.modal('hide'); // Hide modal if it's already shown
        } else {
          modal.modal('show'); // Show modal if it's hidden
        }}
      },[input.posttype])
      const onEmojiClick = (event, emojiObject) => {
        console.log(emojiObject);
        setEnteredText((prevEnteredText) => [
          ...prevEnteredText,
          emojiObject.emoji,
        ]);
      };
      const handleChange = (event) => {
        const { name, value } = event.target;
        setInput((prevInput) => ({
          ...prevInput,
          [name]: value,
        }));
      };
      const handleClick = () => {
        setChildVisible((prevChildVisible) => !prevChildVisible);
      };
      const removeVideo = (i) => {
        const newArray = [...videosPreviewUrls];
        newArray.splice(i, 1);
        setVideosPreviewUrls(newArray);
      };
      const removeImage = (i) => {
        const newArray = [...imagesPreviewUrls];
        newArray.splice(i, 1);
        setImagesPreviewUrls(newArray);
      };
      const _handleVideoChange = (e) => {
        e.preventDefault();
        let newFiles = Array.from(e.target.files);
    
        newFiles.forEach((file) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            const filesize = Math.round(file.size / 1024);
            if (filesize > 200048) {
              alert("File too large, please select a file less than 2mb");
            } else {
              setVideos((prevVideos) => [...prevVideos, file]);
              setVideosPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
            }
          };
          reader.readAsDataURL(file);
        });
      };
      const updateState = (e) => {
        let myArr = e.target.value.split(",");
        myArr = myArr.filter((item) => item);
    
        setEnteredText(myArr);
      };
      const handleSubmit = async (event) => {
        event.preventDefault();
        const validate = () => {
          let isValid = true;
          let newErrors = {};
    
          if (enteredText.length === 0) {
            isValid = false;
            newErrors["post"] = "Please enter post data.";
          }
    
          setErrors(newErrors);
    
          return isValid;
        };
        const urlify = (postcontent) => {
          const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
          return postcontent.toString().replace(urlRegex, (url) => {
            return `<a href="${url}" >${url}</a>`;
          });
        };
        if (validate()) {
          if (formfilled === "empty") {
            window.location = "/userprofile";
            return false;
          } else {
            document.getElementById("loadingicon").style.display = "block";
    
            const html = urlify(enteredText);
    
            const obj = JSON.parse(window.localStorage.getItem("user"));
            const formData = new FormData();
            formData.append("userid", obj.value);
            formData.append("post", html);
            formData.append("tagged", checkedItems);
            formData.append("url", input.url);
            formData.append("posttype", input.posttype);
            files.forEach((file) => formData.append("files[]", file));
            videos.forEach((file) => formData.append("videos[]", file));
            formData.append("tagged", JSON.stringify(checkedItems));
    
            try {
              const res = await axios.post(
                "https://domaintobesocial.com/domaintobe/savenewsfeed",
                formData
              );
              swal({
                title: "Success!",
                text: "Create Successfully Done",
                icon: "success",
               
              });
              document.getElementById("loadingicon").style.display = "none";
              setEnteredText("");
              setImagesPreviewUrls([]);
              setVideosPreviewUrls([]);
              setFiles([]);
              setVideos([]);
             setCheckedItems([]);
             handleClick()
              window.$('#exampleModal12').modal('hide')
              if (res.data.message === "success") {
                setupdatevalue(updatevalue + 1);
              } else {
                alert(res.data.message);
              }
            } catch (error) {
              console.error("Error submitting data:", error.message);
            }
          }
        }
      };

      const _handleImageChange = (e) => {
        e.preventDefault();
        let newFiles = Array.from(e.target.files);
    
        newFiles.forEach((file) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            const filesize = Math.round(file.size / 1024);
            if (filesize > 200048) {
              swal(
                "!Oops",
                "File too large, please select a file less than 2mb",
                "error"
              );
            } else {
              setFiles((prevFiles) => [...prevFiles, file]);
              setImagesPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
            }
          };
          reader.readAsDataURL(file);
        });
      };
      let curentlogin = JSON.parse(window.localStorage.getItem("user"));
      useEffect(()=>{
        const fetchData = async () => {
            try {
            const formData = new FormData();
            formData.append("id", curentlogin.value);
            formData.append("user", curentlogin.value);
    
            const response = await axios.post(
              "https://domaintobesocial.com/domaintobe/getuserprofile",
              formData
            );
    
            setInput({
              name: response.data.message.name,
              email: response.data.message.email,
              uid: response.data.message.id,
            });
    
            setFormfilled(response.data.message.formfilled);
    
            if (response.data.message.roles === "vip") {
              setIsViprole(true);
            } else {
              setIsViprole(false);
            }
    
            setUserimage(response.data.message.image || "/images/blank.png");
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }}
          fetchData();
      },[])
  return (
<>
<div className="modal fade" id="exampleModal12" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content border-0 main-wapper-popup">
      <div className="modal-header border-0">
        <h5 className="modal-title" id="exampleModalLabel">Create Post</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handlecreatepost}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body post-body">
        <div className="create-post-popup">
          <div className="d-flex">
            <Link to="/userprofile">
              <span className="userimg">
                {/* <img src={userimage} align="icon" /> */}
              </span>
            </Link>
            <div>
              <h4>{input.name}</h4>
              <div className="dropdown custom-drop">
       
                   <select name="posttype" className="form-control" value={input.posttype} onChange={handleChange}>
                    
                     <option value="public">Public</option>
               
                     <option value="nonvip" >Friend/Family</option>
                     {isViprole&&<option value="vip">VIP</option>}
                   </select>
           
              </div>
            </div>
          </div>
          <textarea
            name="post"
            className="form-control mt-3"
            placeholder="What's on your mind?"
            value={enteredText}
            onChange={updateState}
           
          />
          <div className="text-danger">{errors.post}</div>
          <div className="maindivkap">
            <div className="row">
              {imagesPreviewUrls.map((imagePreviewUrl, i) => (
                <div className="col-4 col-sm-3 col-lg-2" onClick={() => removeImage(i)}>
                  <div className="imgg">
                    <i className="fa fa-times"></i>
                    <img className="upim w-100" key={imagePreviewUrl} alt="previewImg" src={imagePreviewUrl} />
                  </div>
                </div>
              ))}
              {videosPreviewUrls.map((videoPreviewUrl, i) => (
                <div className="col-sm-3 col-lg-2">
                  <div className="imgg">
                    <i className="fa fa-times" onClick={() => removeVideo(i)}></i>
                    <video width="320" height="240" controls>
                      <source src={videoPreviewUrl} type="video/mp4" />
                    </video>
                  </div>
                </div>
              ))}
            </div>
            <div className="appendusers">
          {checkedItems.map((checkedItem, index) => {
          
            return (
              <div className="item">
                <i className="fa fa-times"></i>

                {checkedItem.image ? (
                  <span className="usersimage">
                    <img
                      className="w-100"
                      src={checkedItem.image}
                      align="icon"
                    />
                  </span>
                ) : (
                  <span className="usersimage">
                    <img
                      className="w-100"
                      src="images/userimg2.jpg"
                      align="icon"
                    />
                  </span>
                )}

                <h5>{checkedItem.name}</h5>
              </div>
            );
          })}
        </div>
        {childVisible && <Picker onEmojiClick={onEmojiClick} />}
          </div>
        </div>
      </div>
      <div className="footer-post d-flex justify-content-between align-items-center modal-footer border">
        <ul className="popupli">
        {isViprole && (<li><a href="#" className="dropdown" data-toggle="modal" data-target="#exampleModalHelp"><i className="fa-solid fa-user-plus" /></a></li>)}
          <li>
            <a href="#">
              <input type="file" name="" onChange={_handleImageChange} multiple  accept="image/jpeg, image/jpg, image/png, image/gif" className="addimagepost" />
              <i className="fa-solid fa-image" />
            </a>
          </li>
          {isViprole && (
            <>
              <li>
                <a href="#">
                  <input type="file" name="" onChange={_handleVideoChange} multiple accept="video/*,mp4/*" className="addimagepost" />
                  <i className="fa-solid fa-photo-film" />
                </a>
              </li>
              
            </>
          )}
          <li>
                <a href="#" data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true" >
         
                <i className="fa-solid fa-link"></i>
                </a>
                <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      x-placement="bottom-start"
                    >
                      <input
                        type="text"
                        className="form-control linkurl"
                        name="url"
                        placeholder="Add Url"
                        onChange={handleChange}
                        id="url"
                        value={input.url}
                      />
                    </div>
              </li>
            
          <li><a href="#"  onClick={handleClick}><i className="fa-regular fa-face-smile"/></a></li>
        </ul>
        
        <button className="btn" type="submit"  onClick={handleSubmit}>Post</button>
      </div>
    </div>
  </div>
</div></>
  )
}

export default CreateFeed