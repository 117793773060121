import React from 'react'
import Header from './Compononets/Header-site/Header'

const Privacy = () => {
  return (<>

  <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className='private-policy'>
              <h1>Privacy Policy</h1>
              <p>
              PRIVATE POLICY Domaintobesocial Is a Public Platform
Much of the information on the Services is public and accessible to everyone, even without an account. By using the Services, you are directing us to share this information publicly and freely.

When you submit content (e.g., a post, comment, or chat message) to a public part of the Services, any visitors to and users of our Services will be able to see that content, the username associated with the content, and the date and time you originally submitted the content. That content and information may also be available in search results on Internet search engines like Google. You should take the public nature of the Services into consideration before posting.

Your Domaintobesocial account has a profile page that is public. Your profile contains information about your activities on the Services, such as your username, prior posts and comments, profile display name, about section, and social links, avatar or profile image, moderator, contributor, and Domaintobesocial Premium status, communities you are active in, and how long you have been a member of the Services (your active day).

We offer social sharing features that let you share content or actions you take on our Services with other media. Your use of these features enables the sharing of certain information with your friends or the public, depending on the settings you establish with the third party that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the third parties that provide these social sharing features (e.g., Tumblr, Facebook, and X).

Domaintobesocial allows moderators to access Domaintobesocial content using moderator bots and tools. Domaintobesocial also allows other third parties to access public Domaintobesocial content using Domaintobesocial's developer services, including Domaintobesocial Embeds, our APIs, Developer Platform, and similar technologies. We limit third-party access to this content and require third parties to pay licensing fees for access to larger quantities of content. Domaintobesocial's Developer Terms are our standard terms governing how these services are used by third parties.

              </p>
              <p>How We Share Information
In addition to the information that is displayed publicly as described above, we may share information in the following ways:

With your consent. We may share information about you with your consent or at your direction.
With linked services. If you link your Domaintobesocial account with a third-party service, Domaintobesocial will share the information you authorize with that third-party service. You can control this sharing as described in "Your Rights and Choices" below.
With our service providers. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work for us. Their use of personal data will be subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who process transactions on our behalf, (ii) cloud providers who host our data and our services, (iii) third-party ads measurement providers who help us and advertisers measure the performance of ads shown on our Services, and (iv) compliance providers who help us determine your eligibility to participate in Domaintobesocial Programs.
To comply with the law. We may share information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request. Our Transparency Report has additional information about how we respond to government requests.
In an emergency. We may share information if we believe it's necessary to prevent imminent and serious bodily harm to a person.
To enforce our rights and promote safety and security. We may share information if we believe your actions are inconsistent with our User Agreement, rules, or other Domaintobesocial terms and policies, or to protect the rights, property, and safety of the Services, ourselves, and others.
With our affiliates. We may share information between and among Domaintobesocial, and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership.
Aggregated or de-identified information. We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a post has been upvoted without identifying who the visitors were, or we may tell an advertiser how many people saw their ad.
How We Protect Your Information</p>
              <p>
              We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is being transmitted. We also enforce technical and administrative access controls to limit which of our employees have access to non-public personal information.

You can help maintain the security of your account by configuring two-factor authentication.

We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law. For example, if you violate our policies and your account is suspended or banned, we may store the identifiers used to create the account (such as phone number) to prevent you from creating new accounts.

Your Rights and Choices
You have choices about how to protect and limit the collection, use, and sharing of information about you when you use the Services. Depending on where you live, you may also have the right to correction/rectification of your personal information, to opt out of certain advertising practices, or to withdraw consent for processing where you have previously provided consent. Below we explain how to exercise each of these rights. Domaintobesocial does not discriminate against users for exercising their rights under data protection laws.

Accessing and Changing Your Information

You can access your information and change or correct certain information through the Services. See our Help Center page for more information. You can also request a copy of the personal information Domaintobesocial maintains about you by following the process described here.

Deleting Your Account

You may delete your account information at any time from the user preferences page. You can also submit a request to delete the personal information Domaintobesocial maintains about you by following the process described in the "Your Rights - Data Subject and Consumer Information Requests" section below. When you delete your account, your profile is no longer visible to other users and disassociated from content you posted under that account. Please note, however, that the posts, comments, and messages you submitted prior to deleting your account will still be visible to others unless you first delete the specific content. After you submit a request to delete your account, it may take up to 90 days for our purge script to complete deletion. We may also retain certain information about you for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law.
</p>
<p>
Controlling Linked Services' Access to Your Account

You can review the services you have permitted to access your account and revoke access to individual services by visiting your account's Apps page (for third-party app authorizations) and the Connected Accounts section of your Account Settings (for Google Sign-In, Sign in with Apple, and connected Twitter accounts), and the Social links section of your Profile Settings.

Controlling Personalized Advertising

You may opt out of us using information we collect from third parties, including advertising partners, to personalize the ads you see on Domaintobesocial. To do so, visit the Privacy section of the User Settings in your account here, if using desktop, and in your Account Settings if using the Reddit mobile app.

Controlling the Use of Cookies

Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services. For more information on controlling how cookies and similar technologies are used on domaintobesocial, see our Cookie Notice.
</p>
<p>
Controlling Advertising and Analytics

Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt out. For example, you may manage the use and collection of certain information by Google Analytics via the Google Analytics Opt-out Browser Add-on. You can opt out of the Audience Measurement services provided by Nielsen and Quantcast.

You may also generally opt out of receiving personalized advertisements from certain third-party advertisers and ad networks. To learn more about these advertisements or to opt out, please visit the sites of the Digital Advertising Alliance and the Network Advertising Initiative, or if you are a user in the European Economic Area, Your Online Choices.

Do Not Track

Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.

Controlling Promotional Communications

You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications or by updating your email options in your account preferences here. If you opt out of promotional communications, we may still send you non-promotional communications, such as information about your account or your use of the Services.

Controlling Mobile Notifications

With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.

Controlling Location Information

You can control how we use location information for feed and recommendations customization via the Safety and Privacy settings setting in your Account Settings.

If you have questions or are not able to submit a request to exercise your rights using the mechanisms above, you may also email us at suport@domaintobesocial.com from the email address that you have verified with your Reddit account, or submit them here.

Before we process a request from you about your personal information, we need to verify the request via your access to your Reddit account or to a verified email address associated with your Reddit account. If we deny your request, you may appeal our decision by contacting us at support@domaintobesocial.com.

              </p>
              <p>International Data Transfers
Domaintobesocial, Corp. is based in the United States and we process and store information on servers located in the United States. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the United States and other countries, where you may not have the same rights as you do under local law.

When we transfer the personal data of users in the EEA, UK and/or Switzerland, we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed 'adequate' under applicable laws.

Additional Information for EEA and UK Users
If you live in the European Economic Area ("EEA"), Reddit Netherlands B.V., is the controller of information processed in connection with the Domaintobesocial platform and this policy. If you live in the United Kingdom (“UK”), Domaintobesocial, Inc., is the data controller and Domaintobesocial UK Limited is Domaintobesocial's UK GDPR Article 27 Representative.

Users in the EEA and UK have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised as described in the "Your Rights and Choices section above. EEA users also have the right to lodge a complaint with their local supervisory authority.

As required by applicable law, we collect and process information about individuals in the EEA and UK only where we have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the following legal bases:

You have consented for us to do so for a specific purpose;
We need to process the information to provide the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;
It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, personalizing the Services, and marketing and promoting the Services; or
</p><p>
We need to process your information to comply with our legal obligations.
Additional Information for California Users
The California Consumer Privacy Act ("CCPA"), as amended, requires us to provide California residents with some additional information, which we address in this section.

We will collect the following categories of personal information from California residents, depending on the Services used:

Identifiers and account information, like your Domaintobesocial username, email address, phone number, IP address, and cookie information.
Commercial information, including information about transactions you undertake with us.
Internet or other electronic network activity information, such as information about your activity on our Services and limited information about your activity on the services of advertisers who use our advertising technology.
Geolocation information based on your IP address.
Your messages with other users (e.g., private messages, chats, and modmail).
Audiovisual information in pictures, audio, or video content submitted to Domaintobesocial.
Professional or employment-related information or demographic information, but only if you explicitly provide it to us, such as by applying for a job or filling out a survey.
Inferences we make based on other collected data, for purposes such as recommending content, advertising, and analytics.
You can find more information about (a) what we collect and sources of that information in the "Information We Collect" section of this notice, (b) the business and commercial purposes for collecting that information in the "How We Use Your Information" section, and (c) the categories of third parties with whom we share that information in the "How We Share Information" section.

If you are a California resident, you have additional rights under the CCPA, including the right to opt out of any sales or sharing of your personal information, to request access to and information about our data practices, and to request deletion or correction of your personal information, as well as the right not to be discriminated against for exercising your privacy rights. Domaintobesocial does not "sell" or "share" personal information as those terms are defined under the CCPA. We do not use or disclose sensitive personal information except to provide you the Services or as otherwise permitted by the CCPA.
</p><p>
You may exercise your rights to access, delete, or correct your personal information as described in the "Your Rights and Choices" section of this notice. When you make a request, we will verify your identity by asking you to sign into your account or if necessary by requesting additional information from you. You may also make a rights request using an authorized agent. If you submit a rights request from an authorized agent who does not provide a valid power of attorney, we may ask the authorized agent to provide proof that you gave the agent signed permission to submit the request to exercise rights on your behalf. In the absence of a valid power of attorney, we may also require you to verify your own identity directly with us or confirm to us that you otherwise provided the authorized agent permission to submit the request. If you have any questions or concerns, you may reach us using the methods described under "Your Rights and Choices" or by emailing us at Domaintobesocialdatarequests@redditdomaintobesocial.com.

Children
Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, if you are located outside the United States, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.

Changes to This Policy
We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your account (if you have chosen to provide an email address) or by otherwise providing notice through our Services. We encourage you to review the Privacy Policy regularly to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.

Contact Us
If you have other questions about this Privacy Policy, please contact us at:

support@domaintobesocial.com</p>
            </div>
          </div>
        </div>
      </div>

    {/* <div className="container ">
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <h1>  PRIVATE POLICY </h1>
        
        <p>
      

PRIVATE POLICYDomaintobesocial Is a Public Platform
Much of the information on the Services is public and accessible to everyone, even without an account. By using the Services, you are directing us to share this information publicly and freely.

When you submit content (e.g., a post, comment, or chat message) to a public part of the Services, any visitors to and users of our Services will be able to see that content, the username associated with the content, and the date and time you originally submitted the content. That content and information may also be available in search results on Internet search engines like Google. You should take the public nature of the Services into consideration before posting.

Your Domaintobesocial account has a profile page that is public. Your profile contains information about your activities on the Services, such as your username, prior posts and comments, profile display name, about section, and social links, avatar or profile image, moderator, contributor, and Domaintobesocial Premium status, communities you are active in, and how long you have been a member of the Services (your active day).

We offer social sharing features that let you share content or actions you take on our Services with other media. Your use of these features enables the sharing of certain information with your friends or the public, depending on the settings you establish with the third party that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the third parties that provide these social sharing features (e.g., Tumblr, Facebook, and X).

Domaintobesocial allows moderators to access Domaintobesocial content using moderator bots and tools. Domaintobesocial also allows other third parties to access public Domaintobesocial content using Domaintobesocial's developer services, including Domaintobesocial Embeds, our APIs, Developer Platform, and similar technologies. We limit third-party access to this content and require third parties to pay licensing fees for access to larger quantities of content. Domaintobesocial's Developer Terms are our standard terms governing how these services are used by third parties.

How We Share Information
In addition to the information that is displayed publicly as described above, we may share information in the following ways:

With your consent. We may share information about you with your consent or at your direction.
With linked services. If you link your Domaintobesocial account with a third-party service, Domaintobesocial will share the information you authorize with that third-party service. You can control this sharing as described in "Your Rights and Choices" below.
With our service providers. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work for us. Their use of personal data will be subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who process transactions on our behalf, (ii) cloud providers who host our data and our services, (iii) third-party ads measurement providers who help us and advertisers measure the performance of ads shown on our Services, and (iv) compliance providers who help us determine your eligibility to participate in Domaintobesocial Programs.
To comply with the law. We may share information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request. Our Transparency Report has additional information about how we respond to government requests.
In an emergency. We may share information if we believe it's necessary to prevent imminent and serious bodily harm to a person.
To enforce our rights and promote safety and security. We may share information if we believe your actions are inconsistent with our User Agreement, rules, or other Domaintobesocial terms and policies, or to protect the rights, property, and safety of the Services, ourselves, and others.
With our affiliates. We may share information between and among Domaintobesocial, and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership.
Aggregated or de-identified information. We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a post has been upvoted without identifying who the visitors were, or we may tell an advertiser how many people saw their ad.
How We Protect Your Information
We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is being transmitted. We also enforce technical and administrative access controls to limit which of our employees have access to non-public personal information.

You can help maintain the security of your account by configuring two-factor authentication.

We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law. For example, if you violate our policies and your account is suspended or banned, we may store the identifiers used to create the account (such as phone number) to prevent you from creating new accounts.

Your Rights and Choices
You have choices about how to protect and limit the collection, use, and sharing of information about you when you use the Services. Depending on where you live, you may also have the right to correction/rectification of your personal information, to opt out of certain advertising practices, or to withdraw consent for processing where you have previously provided consent. Below we explain how to exercise each of these rights. Domaintobesocial does not discriminate against users for exercising their rights under data protection laws.

Accessing and Changing Your Information

You can access your information and change or correct certain information through the Services. See our Help Center page for more information. You can also request a copy of the personal information Domaintobesocial maintains about you by following the process described here.

Deleting Your Account

You may delete your account information at any time from the user preferences page. You can also submit a request to delete the personal information Domaintobesocial maintains about you by following the process described in the "Your Rights - Data Subject and Consumer Information Requests" section below. When you delete your account, your profile is no longer visible to other users and disassociated from content you posted under that account. Please note, however, that the posts, comments, and messages you submitted prior to deleting your account will still be visible to others unless you first delete the specific content. After you submit a request to delete your account, it may take up to 90 days for our purge script to complete deletion. We may also retain certain information about you for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law.

Controlling Linked Services' Access to Your Account

You can review the services you have permitted to access your account and revoke access to individual services by visiting your account's Apps page (for third-party app authorizations) and the Connected Accounts section of your Account Settings (for Google Sign-In, Sign in with Apple, and connected Twitter accounts), and the Social links section of your Profile Settings.

Controlling Personalized Advertising

You may opt out of us using information we collect from third parties, including advertising partners, to personalize the ads you see on Domaintobesocial. To do so, visit the Privacy section of the User Settings in your account here, if using desktop, and in your Account Settings if using the Reddit mobile app.

Controlling the Use of Cookies

Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services. For more information on controlling how cookies and similar technologies are used on domaintobesocial, see our Cookie Notice.

Controlling Advertising and Analytics

Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt out. For example, you may manage the use and collection of certain information by Google Analytics via the Google Analytics Opt-out Browser Add-on. You can opt out of the Audience Measurement services provided by Nielsen and Quantcast.

You may also generally opt out of receiving personalized advertisements from certain third-party advertisers and ad networks. To learn more about these advertisements or to opt out, please visit the sites of the Digital Advertising Alliance and the Network Advertising Initiative, or if you are a user in the European Economic Area, Your Online Choices.

Do Not Track

Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.

Controlling Promotional Communications

You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications or by updating your email options in your account preferences here. If you opt out of promotional communications, we may still send you non-promotional communications, such as information about your account or your use of the Services.

Controlling Mobile Notifications

With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.

Controlling Location Information

You can control how we use location information for feed and recommendations customization via the Safety and Privacy settings setting in your Account Settings.

If you have questions or are not able to submit a request to exercise your rights using the mechanisms above, you may also email us at suport@domaintobesocial.com from the email address that you have verified with your Reddit account, or submit them here.

Before we process a request from you about your personal information, we need to verify the request via your access to your Reddit account or to a verified email address associated with your Reddit account. If we deny your request, you may appeal our decision by contacting us at support@domaintobesocial.com.

International Data Transfers
Domaintobesocial, Corp. is based in the United States and we process and store information on servers located in the United States. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the United States and other countries, where you may not have the same rights as you do under local law.

When we transfer the personal data of users in the EEA, UK and/or Switzerland, we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed 'adequate' under applicable laws.

Additional Information for EEA and UK Users
If you live in the European Economic Area ("EEA"), Reddit Netherlands B.V., is the controller of information processed in connection with the Domaintobesocial platform and this policy. If you live in the United Kingdom (“UK”), Domaintobesocial, Inc., is the data controller and Domaintobesocial UK Limited is Domaintobesocial's UK GDPR Article 27 Representative.

Users in the EEA and UK have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised as described in the "Your Rights and Choices section above. EEA users also have the right to lodge a complaint with their local supervisory authority.

As required by applicable law, we collect and process information about individuals in the EEA and UK only where we have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the following legal bases:

You have consented for us to do so for a specific purpose;
We need to process the information to provide the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;
It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, personalizing the Services, and marketing and promoting the Services; or
We need to process your information to comply with our legal obligations.
Additional Information for California Users
The California Consumer Privacy Act ("CCPA"), as amended, requires us to provide California residents with some additional information, which we address in this section.

We will collect the following categories of personal information from California residents, depending on the Services used:

Identifiers and account information, like your Domaintobesocial username, email address, phone number, IP address, and cookie information.
Commercial information, including information about transactions you undertake with us.
Internet or other electronic network activity information, such as information about your activity on our Services and limited information about your activity on the services of advertisers who use our advertising technology.
Geolocation information based on your IP address.
Your messages with other users (e.g., private messages, chats, and modmail).
Audiovisual information in pictures, audio, or video content submitted to Domaintobesocial.
Professional or employment-related information or demographic information, but only if you explicitly provide it to us, such as by applying for a job or filling out a survey.
Inferences we make based on other collected data, for purposes such as recommending content, advertising, and analytics.
You can find more information about (a) what we collect and sources of that information in the "Information We Collect" section of this notice, (b) the business and commercial purposes for collecting that information in the "How We Use Your Information" section, and (c) the categories of third parties with whom we share that information in the "How We Share Information" section.

If you are a California resident, you have additional rights under the CCPA, including the right to opt out of any sales or sharing of your personal information, to request access to and information about our data practices, and to request deletion or correction of your personal information, as well as the right not to be discriminated against for exercising your privacy rights. Domaintobesocial does not "sell" or "share" personal information as those terms are defined under the CCPA. We do not use or disclose sensitive personal information except to provide you the Services or as otherwise permitted by the CCPA.

You may exercise your rights to access, delete, or correct your personal information as described in the "Your Rights and Choices" section of this notice. When you make a request, we will verify your identity by asking you to sign into your account or if necessary by requesting additional information from you. You may also make a rights request using an authorized agent. If you submit a rights request from an authorized agent who does not provide a valid power of attorney, we may ask the authorized agent to provide proof that you gave the agent signed permission to submit the request to exercise rights on your behalf. In the absence of a valid power of attorney, we may also require you to verify your own identity directly with us or confirm to us that you otherwise provided the authorized agent permission to submit the request. If you have any questions or concerns, you may reach us using the methods described under "Your Rights and Choices" or by emailing us at Domaintobesocialdatarequests@redditdomaintobesocial.com.

Children
Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, if you are located outside the United States, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.

Changes to This Policy
We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your account (if you have chosen to provide an email address) or by otherwise providing notice through our Services. We encourage you to review the Privacy Policy regularly to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.

Contact Us
If you have other questions about this Privacy Policy, please contact us at:

support@domaintobesocial.com

        </p>
      </div>
    </div>
  </div> */}
  </>
  )
}

export default Privacy