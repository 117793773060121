import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCF6C05ArotOhj06jx01NK1VIoM3HJjeWQ",
  authDomain: "domaintobesocial-93568.firebaseapp.com",
  databaseURL: "https://domaintobesocial-93568-default-rtdb.firebaseio.com",
  projectId: "domaintobesocial-93568",
  storageBucket: "domaintobesocial-93568.appspot.com",
  messagingSenderId: "778233196783",
  appId: "1:778233196783:web:7872923dd9752661e86b1a",
  measurementId: "G-BRGP0JNM47"
};

const appvalue = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export default appvalue;