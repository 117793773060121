import React from 'react'
import Header from './Compononets/Header-site/Header'

const Termandconditions = () => {
  return (<> 

<div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className='private-policy'>
              <h1>TERMS AND CONDITIONS</h1>
              <p>
              Domaintobesocial.com User Agreement if you live outside the EEA, United Kingdom, or
Switzerland, including if you live in the United States
Hello, Domaintobesocial.com and people of the Internet! This Domaintobesocial.com User
Agreement (“Terms”) applies to your access to and use of the websites, mobile apps,
widgets, APIs, emails, and other online products and services (collectively, the
“Services”) provided by Reddit, Inc. (“Domaintobesocial.com,” “we,” “us,” or “our”).
Remember Domaintobesocial.com is for fun, and help and is intended to be a place for your
entertainment and educational purposes by professionals-only profiles, but we still need
some basic rules. By accessing or using our Services, you agree to be bound by these
Terms. If you do not agree to these Terms, you may not access or use our Services.
</p>
<p>
1. Your Access to the Services
No one under 13 is allowed to use or access the Services. We may offer additional
Services that require you to be older to use them, so please read all notices and any
Additional Terms carefully when you access the Services.
By using the Services, you state that:
You are at least 13 years old and over the minimum age required by the laws of your
country of residence to access and use the Services;
You can form a binding contract with Domaintobesocial.com, or, if you are over 13 but
under the age of majority in your jurisdiction, that your legal guardian has reviewed and
agrees to these Terms;
You are not barred from using the Services under all applicable laws; and
You have not been permanently suspended or removed from the Services.
If you are accepting these Terms on behalf of another legal entity, including a business
or government entity, you represent that you have full legal authority to bind such
entity to these Terms.
</p>
<p>
2. Privacy
Domaintobesocial.com Privacy Policy explains how and why we collect, use, and share
information about you when you access or use our Services. You understand that through
your use of the Services, you consent to the collection and use of this information as
set forth in the Privacy Policy.
</p>
<p>
3. Your Use of the Services
Subject to your complete and ongoing compliance with these Terms, Domaintobesocial.com
grants you a personal, non-transferable, non-exclusive, revocable, limited license to:
(a) install and use a copy of our mobile application associated with the Services that is
obtained from a legitimate marketplace on a mobile device owned or controlled by you; and
(b) access and use the Services. We reserve all rights not expressly granted to you by
these Terms; (c) if you breach these terms your account will be suspended and or deleted
permanently without notice and without prejudice.
Except and solely to the extent such a restriction is impermissible under applicable law,
you may not, without our written agreement:
license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the
Services or Content;
modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part
of the Services or Content; or
access the Services or Content in order to build a similar or competitive website,
product, or service, except as permitted under any Additional Terms (as defined below).
We are always improving our Services. This means we may add or remove features, products,
or functionalities; we will try to notify you beforehand, but that won’t always be
possible. We reserve the right to modify, suspend, or discontinue the Services (in whole
or in part) at any time, with or without notice to you. Any future release, update, or
other addition to functionality of the Services will be subject to these Terms, which may
be updated from time to time. You agree that we will not be liable to you or to any third
party for any modification, suspension, or discontinuation of the Services or any part
thereof.
</p>
<p>
4. Your Domaintobesocial.com Account and Account Security
To use certain features of our Services, you may be required to create a
Domaintobesocial.com account (an “Account”) and provide us with a username, password,
email and cell phone number and certain other information about yourself as set forth in
the Privacy Policy.
You are solely responsible for the information associated with your Account and anything
that happens related to your Account. You must maintain the security of your Account and
immediately notify Domaintobesocial.com if you discover or suspect that someone has
accessed your Account without your permission. We recommend that you use a strong
password that is used only with your Account and enable two-factor authentication.
You will not license, sell, or transfer your Account without our prior written approval.
</p>
<p>
5. Your Content
The Services may contain information, text, links, graphics, photos/ nudity, videos/
nudity, audio, streams, software, tools, or other materials (“Content”), including
Content created with or submitted to the Services by you or through your Account (“Your
Content”). We take no responsibility for and do not expressly or implicitly endorse,
support, or guarantee the completeness, truthfulness, accuracy, or reliability of any of
Your Content. Your consent is your responsibility to provide no illegal content
prohibited by the governing laws of your country, state, or Province. your content will
be forwarded to the proper authorities.
By submitting Your Content to the Services, you represent and warrant that you have all
rights, power, and authority necessary to grant the rights to Your Content contained
within these Terms. Because you alone are responsible for Your Content, you may expose
yourself to liability if you post or share Content without all necessary rights.
You retain any ownership rights you have in Your Content, but you grant
Domaintobesocial.com the following license to use that Content:
When Your Content is created with or submitted to the Services, you grant us a worldwide,
royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable
license to use, copy, modify, adapt, prepare derivative works of, distribute, store,
perform, and display Your Content and any name, username, voice, or likeness provided in
connection with Your Content in all media formats and channels now known or later
developed anywhere in the world. UNLESS you copyright your content with te chamber of
commerce and provide proof of documentation of such rights. This license includes the
right for us to make Your Content available for syndication, broadcast, distribution, or
publication by other companies, organizations, or individuals who partner with
Domaintobesocial.com. You also agree that we may remove metadata associated with Your
Content, and you irrevocably waive any claims and assertions of moral rights or
attribution with respect to Your Content.
Any ideas, suggestions, and feedback about Domaintobesocial.com or the Services that you
provide to us are entirely voluntary, and you agree that Domaintobesocial.com may use
such ideas, suggestions, and feedback without compensation or obligation to you unless
copyright registration is provided as proof of protected content. If the content is
copyright protected, it must display (C) copyright and year copyrighted, etc.
Although we have no obligation to screen, edit, or monitor Your Content, we may, in our
sole discretion, delete or remove Your Content at any time and for any reason, including
for violating these Terms, violating our Content Policy, or if you otherwise create or
are likely to create liability for us.
</p>
<p>
6. Third-Party Content, Advertisements, and Promotions
The Services may contain links to third-party websites, products, or services, which may
be posted by advertisers, our affiliates, our partners, or other users (“Third-Party
Content”). Third-Party Content is not under our control, and we are not responsible for
any third party’s websites, products, or services. Your use of Third-Party Content is at
your own risk and you should make any investigation you feel necessary before proceeding
with any transaction in connection with such Third-Party Content.
The Services may also contain sponsored Third-Party Content or advertisements. The type,
degree, and targeting of advertisements are subject to change, and you acknowledge and
agree that we may place advertisements in connection with the display of any Content or
information on the Services, including Your Content. Excluding VIP members if opted out.
If you choose to use the Services to conduct a promotion, including a contest or
sweepstakes (“Promotion”), you alone are responsible for conducting the Promotion in
compliance with all applicable laws and regulations, including creating official rules,
offer terms, eligibility requirements, and compliance with applicable laws, rules, and
regulations which govern the Promotion (such as licenses, registrations, bonds, and
regulatory approval). Your Promotion must state that the Promotion is not sponsored by,
endorsed by, or associated with Reddit, and the rules for your Promotion must require
each entrant or participant to release Domaintobesocial.com from any liability related to
the Promotion. You acknowledge and agree that we will not assist you in any way with your
Promotion, and you agree to conduct your Promotion at your own risk.
</p>
<p>
7. Things You Cannot Do
When using or accessing the Services, you must comply with these Terms and all applicable
laws, rules, and regulations. Please review the Content Policy, which is incorporated by
this reference into, and made a part of, these Terms and contains Domaintobesocial.com
rules about prohibited content and conduct. In addition to what is prohibited in the
Content Policy, you may not do any of the following:
Use the Services in any manner that could interfere with, disable, disrupt, overburden,
or otherwise impair the Services;
Gain access to (or attempt to gain access to) another user’s Account or any non-public
portions of the Services, including the computer systems or networks connected to or used
together with the Services;
Upload, transmit, or distribute to or through the Services any viruses, worms, malicious
code, or other software intended to interfere with the Services, including its securityrelated features;
Use the Services to violate applicable law or infringe any person’s or entity’s
intellectual property rights or any other proprietary rights;
Access, search, or collect data from the Services by any means (automated or otherwise)
except as permitted in these Terms or in a separate agreement with Domaintobesocial.com
(we conditionally grant permission to crawl the Services in accordance with the
parameters set forth in our robots.txt file, but scraping the Services without
Domaintobesocial.com prior written consent is prohibited); or
Use the Services in any manner that we reasonably believe to be an abuse of or fraud on
Domaintobesocial.com or any payment system.
We encourage you to report content or conduct that you believe violates these Terms or
our Content Policy. We also support the responsible reporting of security
vulnerabilities. To report a security issue, please email domaintobesocial@gmail.com.
</p>
<p>
8. Moderators
Moderating all content is an unofficial, voluntary position that may be available to
users of the Services. We are not responsible for actions taken by the moderators. We
reserve the right to revoke or limit a user’s ability to moderate at any time and for any
reason or no reason, including for a breach of these Terms.
If you choose to moderate a Domaintobesocial.com:
You agree to follow the Moderator Code of Conduct;
You agree that when you receive reports related to a subreddit you moderate, you will
take appropriate action, which may include removing content that violates policy and/or
promptly escalating to Reddit for review;
You are not, and may not represent that you are, authorized to act on behalf of
Domaintobesocial.com;
You may not enter into any agreement with a third party on behalf of
Domaintobesocial.com, or any content that you moderate, without our written approval;
You may not perform moderation actions in return for any form of compensation,
consideration, gift, or favor from third parties;
If you have access to non-public information as a result of moderating a
Domaintobesocial.com, you will use such information only in connection with your
performance as a moderator; and
You may create and enforce rules for the content you moderate, provided that such rules
do not conflict with these Terms, the Content Policy, or the Moderator Code of Conduct.
Domaintobesocial.com reserves the right, but has no obligation, to overturn any action or
decision of a moderator if Domaintobesocial.com, in its sole discretion, believes that
such action or decision is not in the interest of Domaintobesocial.com or the
Domaintobesocial.com community.
</p>
<p>
9. Copyright, Trademark, the DMCA, and Takedowns
Domaintobesocial.com respects the intellectual property of others and requires that users
of our Services do the same. We have a policy that includes the removal of any infringing
material from the Services and for the termination, in appropriate circumstances, of
users of our Services who are repeat infringers. If you believe that anything on our
Services infringes a copyright or a trademark that you own or control, you may notify
Domaintobesocial.com Designated Agent by filling out our Copyright Report Form or
Trademark Report Form, or by contacting:
Domaintobesocial.com Agent
RDomaintobesocial, Corp.
copyright@Domaintobesocial.com
See 17 U.S.C. § 512(c)(3) for the requirements of a proper notification. Also, please
note that if you knowingly misrepresent that any activity or material on our Services is
infringing, you may be liable to Domaintobesocial.com for certain costs and damages.
If we remove Your Content in response to a copyright or trademark notice, we will notify
you via Domaintobesocial.com private messaging system. If you believe Your Content was
wrongly removed due to a mistake or misidentification in a copyright notice, you can send
a counter notification via our Copyright Counter Notice Form or to our Copyright Agent
(contact information provided above). Please see 17 U.S.C. § 512(g)(3) for the
requirements of a proper counter notification.
</p>
<p>
10. Paid Services
There are no fees for the use of many aspects of the Services. However, some services,
features, and products may be available for purchase (“Paid Services”). In addition to
these Terms, the Domaintobesocial.com Premium and Virtual Goods Agreement, VIP
membership, also applies to Paid Services. By purchasing or using Paid Services, you
further agree to the Premium and Virtual Goods Agreement and, if applicable, the Previews
Terms of Use.
</p>
<p>
11. Intellectual Property
The Services are owned and operated by Domaintobesocial.com. The visual interfaces,
graphics, design, compilation, information, data, computer code, products, services,
trademarks, and all other elements of the Services (“Materials”) provided by Reddit are
protected by intellectual property and other laws. All Materials included in the Services
are the property of Domaintobesocial.com unless copyrighted and or trademarked licensed,
or its third-party licensors. You acknowledge and agree that you shall not acquire any
ownership rights whatsoever by downloading Materials or by purchasing Paid Services.
Except as expressly authorized by Domaintobesocial.com, and subject to
Domaintobesocial.com Brand Guidelines, you may not make use of the Materials.
Domaintobesocial.com reserves all rights to the Materials not granted expressly in these
Terms.
</p>
<p>
12. Indemnity
Except to the extent prohibited by law, you agree to defend, indemnify, and hold
Domaintobesocial.com, its affiliates, and their respective, directors, officers,
employees, affiliates, agents, contractors, third-party service providers, and licensors
(the “Domaintobesocial.com Entities”) harmless from and against any claim or demand made
by any third party, and any related liability, damage, loss, and expense (including costs
and attorneys’ fees) due to, arising out of, or in connection with: (a) your use of the
Services; (b) your violation of these Terms; (c) your violation of applicable laws or
regulations; or (d) Your Content. We reserve the right to control the defense of any
matter for which you are required to indemnify us, and you agree to cooperate with our
defense of these claims.
</p>
<p>
13. Disclaimers and Limitation of Liability
Nothing in these Terms will prejudice the statutory rights that you may have as a user of
the Services. Some countries, states, provinces or other jurisdictions do not allow the
exclusion of certain warranties or the limitation of liability as stated in this section,
so the below terms may not fully apply to you. Instead, in such jurisdictions, the
exclusions and limitations below shall apply only to the extent permitted by the laws of
such jurisdictions.
THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT REPRESENTATIONS, WARRANTIES,
OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, LEGAL, OR STATUTORY, INCLUDING
IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. 
THE DOMAINTOBESOCIAL.COM ENTITIES DO NOT WARRANT THAT THE SERVICES ARE
ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. DOMAINTOBESOCIAL.COM DOES NOT
CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE
SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE
DOMAINTOBESOCIAL.COM ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO
NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER
HARMFUL COMPONENTS.
IN NO EVENT WILL ANY OF THE DOMAINTOBESOCIAL.COM ENTITIES BE LIABLE TO YOU FOR ANY
INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST
PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING
FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE
DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN
DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
COMPUTER SYSTEM, OR RESULTING LOSS OF DATA. IN NO EVENT WILL THE AGGREGATE LIABILITY OF
THE REDDIT ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT
YOU PAID DOMAINTOBESOCIAL.COM IN THE PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO
THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY,
INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE, STRICT LIABILITY,
OR OTHERWISE, EVEN IF THE DOMAINTOBESOCIAL.COM ENTITIES HAVE BEEN ADVISED OF THE
POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH IN THESE TERMS IS FOUND
TO HAVE FAILED ITS ESSENTIAL PURPOSE.
</p>
<p>
14. Governing Law and Venue
We want you to enjoy Domaintobesocial.com, so if you have an issue or dispute, you agree
to raise it and try to resolve it with us informally. You can contact us with feedback
and concerns here.
To the fullest extent permitted by applicable law, any claims arising out of or relating
to these Terms or the Services will be governed by the laws of the State of Delaware,
without regard to its conflict of laws rules; all disputes related to these Terms or the
Services will be brought solely in the federal or state courts located in Delaware, and
you and Domaintobesocial.com consent to personal jurisdiction in these courts.
If you are a U.S. city, county, or state government entity, then this Section 14 does not
apply to you. If you are a U.S. federal government entity, any claims arising out of or
relating to these Terms or the Services will be governed by the laws of the United States
of America without regard to its conflict of laws rules. To the extent permitted by
federal law, the laws of Delaware (other than its conflict of law rules) will apply in
the absence of applicable federal law. All disputes related to these Terms or the
Services will be brought solely in the federal or state courts located in Delaware.
</p>
<p>
15. Changes to these Terms
We may make changes to these Terms from time to time. If we make changes, we will post
the revised Terms and update the Effective Date above. If the changes, in our sole
discretion, are material, we may also notify you by sending an email to the address
associated with your Account (if you have chosen to provide an email address) or by
otherwise providing you with notice through our Services. By continuing to access or use
the Services on or after the Effective Date of the revised Terms, you agree to be bound
by the revised Terms. If you do not agree to the revised Terms, you must stop accessing
and using our Services before the changes become effective.
</p>
<p>
16. Additional Terms
Because we offer a variety of Services, you may be asked to agree to additional terms,
policies, guidelines, or rules before using a specific product or service offered by
Domaintobesocial.com (collectively, “Additional Terms”). All Additional Terms are
incorporated by this reference into, and made a part of, these Terms, and to the extent
any Additional Terms conflict with these Terms, the Additional Terms govern with respect
to your use of the corresponding Services.
If you use any Paid Services, you must also agree to the Domaintobesocial.com Premium and
Virtual Goods Agreement.
If you use any preview features, you must also agree to the Domaintobesocial.com Previews
Terms of Use.
If you use the VIP membership service, you agree the pay-as-you-go is a non-refundable
service once paid and the service will expire without auto-renewal.
If you use the self-service platform for advertising, you must also agree to the pay-asyou-go,
 non-refundable service for the Advertising Platform Terms.
If you use any of our Developer Services (including our APIs), you must also agree to the
Domaintobesocial.com Developer Terms.
If you participate in any Domaintobesocial.com program that is subject to Additional
Terms (including our Contributor Program), you must also agree to those Additional Terms.
</p>
<p>
17. Termination
You may terminate these Terms at any time and for any reason by deleting your Account and
discontinuing use of all Services. If you stop using the Services without deactivating
your Account, your Account may be deactivated due to prolonged inactivity.
To the fullest extent permitted by applicable law, we may suspend or terminate your
Account, moderator status, or ability to access or use the Services at any time for any
or no reason, including for violating these Terms or our Content Policy.
The following sections will survive any termination of these Terms or of your Account: 5
(Your Content), 7 (Things You Cannot Do), 12 (Indemnity), 13 (Disclaimers and Limitation
of Liability), 14 (Governing Law and Venue), 17 (Termination), and 18 (Miscellaneous).
</p>
<p>
18. Miscellaneous
These Terms, together with the Privacy Policy and any other agreements expressly
incorporated by reference into these Terms, constitute the entire agreement between you
and us regarding your access to and use of the Services. Our failure to exercise or
enforce any right or provision of these Terms will not operate as a waiver of such right
or provision. If any part of these Terms is held to be invalid or unenforceable, the
unenforceable part will be given effect to the greatest extent possible, and the
remaining parts will remain in full force and effect. You may not assign or transfer any
of your rights or obligations under these Terms without our consent. We may freely assign
any of our rights and obligations under these Terms.
Headings are used in these Terms for reference only and will not be considered when
interpreting them. For purposes of these Terms: (a) the words “include,” “includes,” and
“including” will be deemed to be followed by the words “without limitation;” (b) the
words “such as,” “for example,” “e.g.,” and any derivatives of those words will mean by
way of example and the items that follow these words will not be deemed an exhaustive
list; and (c) the word “or” is used in the inclusive sense of “and/or” and the terms
“or,” “any,” and “either” are not exclusive. No ambiguity will be construed against any
party based on a claim that the party drafted the language.
These Terms are a legally-binding agreement between you and Domaintobesocial.com, Corp.
If you have any questions about these Terms, please contact us.
domaintobesocial@gmail.com.
Domaintobesocial.com User Agreement if you live in the EEA, United Kingdom, or
Switzerland
Hello, Domaintobesocial.com and people of the Internet! This Domaintobesocial.com User
Agreement (“Terms”) applies to your access to and use of the websites, mobile apps,
widgets, APIs, emails, and other online products and services (collectively, the
“Services”) provided by Domaintobesocial.com, Corp. (“Domaintobesocial.com,” “we,” “us,”
or “our”).
Domaintobesocial@gmail.com
</p>
            </div>
          </div>
        </div>
      </div>


              

   
  </>
  )
}

export default Termandconditions