import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import UpgreadePlan from "../UpgreadePlan/UpgreadePlan";

function Menubar({value2=null}) {

    const [showSubMenu, setShowSubMenu] = useState(false);

    const [categories, setCategories] = useState([]);
    const [plans, setPlans] = useState([]);
    const [professionsubcategory, setProfessionSubcategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [profession, setProfession] = useState([]);
    const [input, setInput] = useState({
        name: '',
        email: '',
        uid: '',
      });
      const [formfilled, setFormfilled] = useState('');
      const [isViprole, setIsViprole] = useState(false);
      const [verified , setverified ] = useState(false);
      const [userImage, setUserImage] = useState('/images/blank.png');
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const handleSubMenuToggle = () => {
      setShowSubMenu(prevState => !prevState); // Toggle submenu visibility
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formData = new FormData();
                formData.append('id', curentlogin.value);
                formData.append('user', curentlogin.value);
        
                const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
        
                setInput({
                  name: response.data.message.name,
                  email: response.data.message.email,
                  uid: response.data.message.id,
                });
                setverified(response.data.message.emailverified==='1'?true:false);
                setFormfilled(response.data.message.formfilled);
        
                if (response.data.message.roles === 'vip') {
                  setIsViprole(true);
                } else {
                  setIsViprole(false);
                }
        
                setUserImage(response.data.message.image || '/images/blank.png');
        
              } catch (error) {
                console.error('Error fetching data:', error.message);
              }
          try {
            const response = await axios.get('https://domaintobesocial.com/domaintobe/category');
            const sortedCategories = [...response.data.message];
    
            // Sort the categories array alphabetically based on the 'catname' property
            sortedCategories.sort((a, b) => {
              const nameA = a.catname.toUpperCase(); // Ignore case for comparison
              const nameB = b.catname.toUpperCase(); // Ignore case for comparison
    
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // Names are equal
            });
    
            setCategories(sortedCategories);
          } catch (error) {
            console.error('Error fetching data:', error);
          }

          try {
            const response = await axios.get('https://domaintobesocial.com/domaintobe/subcategory');
            setProfessionSubcategory(response.data.message2);
            setSubcategory(response.data.message);
          } catch (error) {
            console.error('Error fetching data:', error);
          }

          try {
            const formData = new FormData();
            formData.append('id', '16');
    
            const response = await axios.post('https://domaintobesocial.com/domaintobe/getprofessions', formData);
            setProfession(response.data.message);
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }


          
        };
    
        fetchData();
      }, []);

     let handleChangeLogout=()=>
      {
        window.localStorage.clear();
        window.location.reload()
      }
      let vipimage;
      if(isViprole){
        vipimage = <img className="vip" src="/images/vip.png" align="icon"/>;
    }else{
        vipimage = '';
    }

    let stringValue = window.localStorage.getItem('user');
        if (stringValue !== null) {
            let value = JSON.parse(stringValue)
            let expirationDate = new Date(value.expirationDate)
            if (expirationDate > new Date()) {
                
            }else{
    
                window.localStorage.removeItem('user');
                window.location = "/";
            }
        }else{
            window.localStorage.removeItem('user');
            window.location = "/";
        }
  
        useEffect(() => {
          const fetchMembershipPlans = async () => {
            try {
              const response5 = await axios.post(
                "https://domaintobesocial.com/domaintobe/membershipplans"
              );
      
              if (response5 && response5.data && response5.data.result) {
                setPlans(response5.data.result);
              } else {
                console.log("No data or unexpected data format in the response.");
              }
            } catch (error) {
              console.log(error.message);
            }
          };
      
          fetchMembershipPlans();
        }, []);

  return (
    <div className='ticket-top main_menu main_menu_new '>

           <ul className="Ticket-header Ticket-header2 mb-0 mt-0">
        <li>
     
            {isViprole?<>  <span
            className="dropdown-toggle active non-active"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        
            onClick={handleSubMenuToggle}
          >
           <img className="vip-feed" src="../images/vip-feed.png" align="icon"/>
            
          </span>
          
          <div className="dropdown-menu customfine" aria-labelledby="dropdownMenuButton">
            <ul>
              
              <li className="dropdown-item"  data-set="check" onClick={()=>value2.setfilterfeed('1')}>  Free Feed</li>
              <li className="dropdown-item"  onClick={()=>value2.setfilterfeed('3')}>Vip Feed</li>
              <li className="dropdown-item"  onClick={()=>value2.setfilterfeed('')}>All Feed</li>
              {/* <li className="dropdown-item"  > <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button></li> */}

              </ul></div></>:<> 
              
            <span
            className="dropdown-toggle active"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        
            onClick={handleSubMenuToggle}
          >
            News Feed
            
          </span>
          
          <div className="dropdown-menu customfine" aria-labelledby="dropdownMenuButton">
            <ul>
              
              <li className="dropdown-item"  data-set="check"> <Link to="/userdashboard" className="active"> News Feed</Link></li>
              <li className="dropdown-item"  data-toggle="modal" data-target="#exampleModalCenter"> Vip Feed</li>
              {/* <li className="dropdown-item"  > <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button></li> */}

              </ul></div></>}    
       
        </li>
        <li className="dropdown">
          <span
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={handleSubMenuToggle}
          >
            Discussion
          </span>
          <div className="dropdown-menu customfine" aria-labelledby="dropdownMenuButton">
            <ul>
              {categories&&categories.map((result) => (
                <li key={result.id} value={result.id} data-set="check">
                  <Link to={"/discussion?type=" + result.id} tabIndex="-1">
                    {result.catname}
                  </Link>
                  <ul className="testul">
                    {subcategory &&
                      subcategory.map((result1) =>
                        result1.catid === result.id ? (
                          <li
                            key={result1.id}
                            value={result1.id}
                            data-set="check"
                          >
                            <Link
                              to={
                                "/discussion?type=" +
                                result.id +
                                "&subtype=" +
                                result1.id
                              }
                            >
                              {result1.subcat}
                            </Link>
                          </li>
                        ) : null
                      )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </li>

        <li className="dropdown">
          <span
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Professional Help
          </span>
          <div className="dropdown-menu customfine Prof" aria-labelledby="dropdownMenuButton">
            <ul>
              {profession&&profession.map((result) => {
                return (
                  <li data-set="check" key={result.id}>
                    <Link to={"/professions/" + result.id}>{result.name}</Link>
                    <ul className="testul">
                      {professionsubcategory &&
                        professionsubcategory.map((result1) =>
                          result1.pid === result.id ? (
                            <li
                              key={result1.id}
                              value={result1.id}
                              data-set="check"
                            >
                              <Link
                                to={
                                  "/professions/" +
                                  result.id +
                                  "&subtype=" +
                                  result1.id
                                }
                              >
                                {result1.name}
                              </Link>
                            </li>
                          ) : null
                        )}
                    </ul>
                  </li>
                );
              })}
              <li data-set="check">
                <Link to={"/help"}>All helps</Link>
              </li>
            </ul>
          </div>
        </li>
        {/* <li><Link to="/blog" >Blog</Link></li> */}
      </ul>
    </div>
  )
}

export default Menubar