import React, { useEffect, useState } from 'react'
import Vipfeed from '../Feeds/Vipfeed'
import axios from 'axios';
import Freefeed from '../Feeds/Freefeed';

const Userdashboard = () => {

  const [isViprole, setIsViprole] = useState();

let curentlogin = JSON.parse(window.localStorage.getItem("user"));

useEffect(() => {
    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('id', curentlogin.value);
            formData.append('user', curentlogin.value);
    
            const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
    
            if (response.data.message.roles === 'vip') {
              setIsViprole(true);
            } else {
              setIsViprole(false);
            }
    
      
    
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }
        }

    fetchData();
  }, []);
  return (
    <>{isViprole&&isViprole?<Vipfeed/>:<Freefeed/>}</>
  )
}

export default Userdashboard