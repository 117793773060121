import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { Link, useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup';
import swal from 'sweetalert';

function Discussion() {
    const [discussionsearch, setDiscussionSearch] = useState(null);
    const [filterValue, setFilterValue] = useState(null)
    const [category, setCategory] = useState([]);
    const [formData, setFormData] = useState(new FormData());
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const [data, setData] = useState([])
    const [Privatedata, setPrivatedata] = useState([])
    const [input, setInput] = useState({
        name: '',
        uid: '',
        category: '', sort: ''
      });
    
      const [isViprole, setIsViprole] = useState(false);
      const [userImage, setUserImage] = useState('/images/blank.png');

    const [freePosts, setFreePosts] = useState(true);
    const [vipPosts, setVipPosts] = useState(false);
    const [PrivatePosts, setPrivatePosts] = useState(false);
    const [inputSort, setInputSort] = useState('');
    const location = useLocation();

    
  
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queries = Object.fromEntries(queryParams.entries());

        const fetchData = async () => {
            try {
              if (queries.type !== undefined || queries.subtype !== undefined) {
                const formData2 = new FormData();
                formData2.append('category', queries.type);
                formData2.append('subcategory', queries.subtype);
                formData2.append('sort', inputSort);
                
                const response = await axios.post('https://domaintobesocial.com/domaintobe/sortdiscussion', formData2);
                setData(response.data.data);
                setPrivatedata(response.data.message2)
              } else {
                const response = await axios.get('https://domaintobesocial.com/domaintobe/getdiscussions');
                setData(response.data.message);
                setPrivatedata(response.data.message2)
              }
            } catch (error) {
              console.log(error.message);
            }
          };
          fetchData();
      
         
      }, [location.search]);
    
  
    let vipimage, navoptions1, navoptions2,navoptions3;
  
    if (isViprole) {
      vipimage = <img className="vip" src="/images/vip.png" align="icon" />;
      navoptions1 = <li className={freePosts ? "active" : ""} onClick={() =>{ setFreePosts(true); setPrivatePosts(false); setVipPosts(false); }}>Free</li>;
      navoptions2 = <li className={vipPosts ? "active" : ""} onClick={() => { setFreePosts(false);setPrivatePosts(false); setVipPosts(true); }}>Vip</li>;
      navoptions3 = <li className={PrivatePosts ? "active" : ""} onClick={() => { setFreePosts(false); setVipPosts(false); setPrivatePosts(true); }}>My Private Post</li>;
    } else {
      vipimage = null; // or an empty string if needed
      navoptions1 = <li className={freePosts ? "active" : ""} onClick={() =>{ setFreePosts(true); setPrivatePosts(false); setVipPosts(false);}}>Free</li>;
      navoptions2 = <li className={vipPosts ? "active" : ""}  data-toggle="modal" data-target="#exampleModalCenter">Vip</li>;
      navoptions3 = <li className={PrivatePosts ? "active" : ""} onClick={() => { setFreePosts(false); setVipPosts(false); setPrivatePosts(true); }}>My Private Post</li>;
    }

    function filterData(data, filterValue) {
        const filteredData = filterValue
          ? data
              .filter((item) =>
                item.description.toLowerCase().includes(filterValue.toLowerCase())
              )
              .map((item) => ({ ...item, name: item.description.toLowerCase() }))
          : data.map((item) => ({ ...item, name: item.description.toLowerCase() }));
      
        return filteredData;
      }
    
    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value === '' ? null : value);
    };
    const searchSubmit = (e) => {
        e.preventDefault();
        setDiscussionSearch(0);
        const searchData = filterValue;

        axios.post('https://domaintobesocial.com/domaintobe/newSearch', { searchData })
            .then((response) => {
                console.log(response);
                setDiscussionSearch(response.data.message);
            })
            .catch((error) => {
                console.log(error.message);
            });
    };
    const handleChange = (event) => {
        setInput({ ...input, [event.target.name]: event.target.value });
       
            
    };
    
    useEffect(()=>{
      const formData = new FormData();
      console.log( input.category)
      formData.append('category', input.category);
      formData.append('sort', input.sort);
      axios.post('https://domaintobesocial.com/domaintobe/sortdiscussion', formData)
          .then((response) => {
              console.log(response.data.data);
              console.log(formData);
              setData(response.data.data);
          })
          .catch((error) => {
              console.log(error.message);
          });
    },[input.category])
    const handleSubmit = () => {
        formData.append('category,id');

        axios.post('https://domaintobesocial.com/domaintobe/sortdiscussion', formData)
            .then((response) => {
                console.log(response.data.data);
                // Update state or do something with the response
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    useEffect(() => {
        const fetchCategory = async () => {
            
            try {
                const curentlogin = JSON.parse(window.localStorage.getItem("user"));
                const formData = new FormData();
                formData.append('id', curentlogin.value);
                formData.append('user', curentlogin.value);
        
                const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
        
                setInput(prevInput => ({
                  ...prevInput,
                  name: response.data.message.name,
                  uid: response.data.message.id,
                }));
        
                setIsViprole(response.data.message.roles === 'vip');
                
                if (!response.data.message.image || response.data.message.image === '') {
                  setUserImage('/images/blank.png');
                } else {
                  setUserImage(response.data.message.image);
                }
              } catch (error) {
                console.log(error.message);
              }
            try {
                const response = await axios.get('https://domaintobesocial.com/domaintobe/category');
                setCategory(response.data.message);
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchCategory();
    }, []);

    
  return (
      <div className="in_center in_center_discussion help">
          <div className="head">
              <form className="d-flex">
                  <input
                      className="form-control me-2"
                      type="search"
                      id="searchForm"
                      placeholder="Search"
                      aria-label="Search"
                      value={filterValue}
                      onChange={handleFilterChange}
                  />
                  <button className="btn" onClick={searchSubmit} type="button">
                      <img src="images/searchicon.png" alt="icon" />
                  </button>
                  <div className="setsearchdata">
                      <ul>
                      {discussionsearch === 0 && <div>...please wait</div>}

{discussionsearch &&
  discussionsearch[0] !== 'data' &&
  discussionsearch.map((results) => {
    return (
      <>
        {results.title ? (
       <Link to={{ pathname: "/singlediscussion", search: `?id=${results.id}` }}>
            <li className="postsearch" key={results.id}>
              {results.title}
              <i id={results.id} className="fas fa-arrow-right"></i>
            </li>
          </Link>
        ) : (
          ''
        )}
      </>
    );
  })}
                      </ul>
                  </div>
              </form>
              <Link to="/createpost" className="hpl">
                  <img src="images/iconS2.png" align="icon" /> <span>Start Discussion</span>
              </Link>
          </div>

          <div className="listusr discussion">  
              <ul className="ul_discussion">
                  <li>
                      <select value={input.category} name="category" id="category" onChange={handleChange}>
                          <option key="" value="">--Select Category--</option>
                          {category.sort((a, b) => a.catname.localeCompare(b.catname)).map((result) => (
                              <option key={result.id} value={result.id} data-set="check">{result.catname}</option>
                          ))}
                      </select>
                  </li>
              </ul>

              {data.length > 0 ? (
                  <div className="listpro">
                      <ul className="showposts">
                      {navoptions1}
                            {navoptions2}
                            {navoptions3}
                      </ul>
                      {data && data.length > 0 ? (
        filterData(data,filterValue).map((result) => (
          <div key={result.id}>
            
            {console.log(result.postaccess)}
            {vipPosts && result.postaccess == 1 &&result.viewpostaccess == 0 ? (
              <div className="vipposts">
                <div className="list1">
                <Link to={{ pathname: "/singlediscussion", search: `?id=${result.id}` }}>
                    <span className="userimg"><img src={result.singleimage} alt="icon" /></span>
                    <h6>{result.description}</h6>
                  </Link>
                  {data.length > 0 && data[0].video && data[0].video !== 'Null' ? (
                    <Popup trigger={<button><i className="fas fa-video"></i></button>} position="right center">
                      <ReactPlayer
                        url={data[0].video}
                        width="100%"
                        controls={true}
                      />
                    </Popup>
                  ) : ''}
                  <p>{result.created} Ago</p>
                  <ul>
                    <li><img src="/images/like.png" alt="icon" /> {result.likes}</li>
                    <li><img src="/images/comment.png" alt="icon" /> {result.comments} Comments</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div>
                {result.postaccess != 1 &&result.viewpostaccess == 0 && freePosts && !vipPosts ? (
                  <div className="freeposts">
                    <div className="list1">
                    <Link to={{ pathname: "/singlediscussion", search: `?id=${result.id}` }}>
                        <span className="userimg"><img src={result.singleimage} alt="icon" /></span>
                        <h6>{result.description}</h6>
                      </Link>
                      {data.length > 0 && data[0].video && data[0].video !== 'Null' ? (
                        <Popup trigger={<button><i className="fas fa-video"></i></button>} position="right center">
                          <ReactPlayer
                            url={data[0].video}
                            width="100%"
                            controls={true}
                          />
                        </Popup>
                      ) : ''}
                      <p>{result.created} Ago</p>
                      <ul>
                        <li><img src="/images/like.png" alt="icon" /> {result.likes}</li>
                        <li><img src="/images/comment.png" alt="icon" /> {result.comments} Comments</li>
                      </ul>
                    </div>
                  </div>
                ) : ''}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="norecord">
          <img src="/images/nodata.png" alt="No data" />
        </div>
      )}

{data&&data.map(result=>(<>
            <div>
           
                {(result.userid == curentlogin.value||result.viewpostaccessdata?.indexOf(curentlogin.value) !== -1)&& (result.viewpostaccess == 1 || result.viewpostaccess == 2) && !freePosts && !vipPosts && PrivatePosts ? (
                  <div className="freeposts">
                    <div className="list1">
                    <Link to={{ pathname: "/singlediscussion", search: `?id=${result.id}` }}>
                        <span className="userimg"><img src={result.singleimage} alt="icon" /></span>
                        <h6>{result.description}</h6>
                      </Link>
                      {data.length > 0 && data[0].video && data[0].video !== 'Null' ? (
                        <Popup trigger={<button><i className="fas fa-video"></i></button>} position="right center">
                          <ReactPlayer
                            url={data[0].video}
                            width="100%"
                            controls={true}
                          />
                        </Popup>
                      ) : ''}
                         <span>{result.viewpostaccess==1?"Private":result.viewpostaccess==2?"family":""} </span>
                      <p>{result.created} Ago</p>
                   
                      <ul>
                        <li><img src="/images/like.png" alt="icon" /> {result.likes}</li>
                        <li><img src="/images/comment.png" alt="icon" /> {result.comments} Comments</li>
                      </ul>
                    </div>
                  </div>
                ) : ''}
              </div></>))}
           {Privatedata&&Privatedata.map(result=>(<>
            <div>
                {result.userid == curentlogin.value && !freePosts && !vipPosts && PrivatePosts ? (
                  <div className="freeposts">
                    <div className="list1">
                    <Link to={{ pathname: "/singlediscussion", search: `?id=${result.id}` }}>
                        <span className="userimg"><img src={result.singleimage} alt="icon" /></span>
                        <h6>{result.description}</h6>
                      </Link>
                      {data.length > 0 && data[0].video && data[0].video !== 'Null' ? (
                        <Popup trigger={<button><i className="fas fa-video"></i></button>} position="right center">
                          <ReactPlayer
                            url={data[0].video}
                            width="100%"
                            controls={true}
                          />
                        </Popup>
                      ) : ''}
                      <p>{result.created} Ago</p>
                      <ul>
                        <li><img src="/images/like.png" alt="icon" /> {result.likes}</li>
                        <li><img src="/images/comment.png" alt="icon" /> {result.comments} Comments</li>
                      </ul>
                    </div>
                  </div>
                ) : ''}
              </div></>))}
           
                  </div>
              ) : (
                  <div className="norecord">
                      <img src="/images/nodata.png" alt="No Data" />
                  </div>
              )}
          </div>
      </div>
  )
}

export default Discussion