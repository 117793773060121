import axios from 'axios';
import React, { useState } from 'react';

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));

  const handleSubmit =  (event) => {
    event.preventDefault();
    // Check if new password and confirm new password match
    if (newPassword !== confirmNewPassword) {
      setError('New password and confirm password do not match');
      return;
    }
    
    // Make Axios request to change password
    try {
        const formData = new FormData();
        formData.append("id", curentlogin.value);
formData.append("oldPassword", currentPassword);
formData.append("newPassword", newPassword);
formData.append("confirmNewPassword", confirmNewPassword);
      const response =  axios.post('https://domaintobesocial.com/domaintobe/userchangePassword',formData );
      
 
      // Reset form fields
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setError('');
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      if (error.response && error.response.data) {
        setError(error.response.data.message); // Assuming API returns error message
      } else {
        setError('An error occurred while changing password');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="currentPassword" className="form-label text-white">Current Password</label>
        <input
          type="password"
          className="form-control"
          id="currentPassword"
          placeholder="Enter current password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="newPassword" className="form-label text-white">New Password</label>
        <input
          type="password"
          className="form-control"
          id="newPassword"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="confirmNewPassword" className="form-label text-white">Confirm New Password</label>
        <input
          type="password"
          className="form-control"
          id="confirmNewPassword"
          placeholder="Confirm new password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          required
        />
      </div>
      {error && <p className="text-danger">{error}</p>}
      <button type="submit" className="btn btn-primary">Change Password</button>
    </form>
  );
};

export default ChangePasswordForm;
